// src/components/UserForm.tsx
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Card, Select } from "antd";
import { useHistory } from "react-router-dom";
import { UserProps } from "../interfaces/user";
import logo from "../assets/advancly-logo-full.png";
import LayoutComponent from "./Layout.component";

const { Title } = Typography;
const { Option } = Select;

const defaultFormData: UserProps = {
  aggregator_id: 11,
  public_key: "uGi7Y3ETLXWlQQps",
  // identity_number: "22222222277",
  identity_number: "29394583331",
  bank_account_number: "0701963637",
  bank_code: "044",
  first_name: "Fashin",
  last_name: "Hanma",
  customer_phone: "08228270207",
  email: "fashihan@yopmail.com",
  country_code: "NG",
  customer_category: "Bolt CTO",
  company_name: "Bolt",
  customStyles: {
    primaryColor: "#713dff", // Default primary color
    primaryDeepColor: "#29067d", // Default deep primary color
    primaryLightColor: "#E3D9FF", // Default light primary color
  },
  customer_type: undefined, // Optional field
  widgetUrl: "https://advancly-borrower.staging.vggdev.com/create-loan-account",
};

interface CustomStyles {
  primaryColor: string;
  primaryDeepColor: string;
  primaryLightColor: string;
}

const defaultCustomStyles: CustomStyles = {
  primaryColor: "#713dff",
  primaryDeepColor: "#29067d",
  primaryLightColor: "#E3D9FF",
};

const UserForm: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [customStyles, setCustomStyles] = useState(defaultCustomStyles);

  useEffect(() => {
    // Load saved form data from session storage
    const savedFormData = sessionStorage.getItem("formData");
    if (savedFormData) {
      const data = JSON.parse(savedFormData);
      form.setFieldsValue(data);
      console.log({ data });
      setCustomStyles({
        primaryColor: data?.customStyles?.primaryColor,
        primaryDeepColor: data?.customStyles?.primaryDeepColor,
        primaryLightColor: data?.customStyles?.primaryLightColor,
      });
    } else {
      form.setFieldsValue(defaultFormData);
    }
  }, [form]);

  const onFinish = (values: UserProps) => {
    if (!values?.customer_phone) {
      values.customer_phone = undefined;
    }
    // Save the current form state to session storage
    sessionStorage.setItem("formData", JSON.stringify(values));
    history.push("/preview", { data: values });
  };

  const handleColorChange = (name: keyof CustomStyles, value: string) => {
    setCustomStyles((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <LayoutComponent>
      <div>
        <Card
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Title
                level={3}
                style={{ color: "var(--primary-color)", marginBottom: "0px" }}
              >
                Widget Data
              </Title>

              <img
                src={logo}
                alt="login"
                style={{
                  width: "100px",
                  objectFit: "contain",
                }}
              />
            </div>
          }
        >
          <Form
            initialValues={defaultFormData}
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            {Object.keys(defaultFormData).map((key) => {
              const isOptional = [
                "identity_number",
                "bank_account_number",
                "bank_code",
                "customer_phone",
              ].includes(key);
              if (
                key !== "customStyles" &&
                key !== "widgetUrl" &&
                key !== "customer_type"
              ) {
                return (
                  <Form.Item
                    key={key}
                    label={key
                      .replace(/_/g, " ")
                      .replace(/^\w/, (c) => c.toUpperCase())}
                    name={key}
                    rules={
                      isOptional
                        ? []
                        : [
                            {
                              required: true,
                              message: `Please input your ${key
                                .replace(/_/g, " ")
                                .toLowerCase()}`,
                            },
                          ]
                    }
                  >
                    <Input size="large" />
                  </Form.Item>
                );
              } else {
                return null; // We handle customStyles separately
              }
            })}
            <Form.Item label="Customer Type" name="customer_type">
              <Select placeholder="Select a customer type" allowClear>
                <Option value="1">Individual</Option>
                <Option value="2">Corporate</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Server URL"
              name="widgetUrl"
              rules={[{ required: true, message: "Please select a server!" }]}
            >
              <Select defaultValue="https://advancly-borrower.staging.vggdev.com/create-loan-account">
                <Option value="http://localhost:3000/create-loan-account">
                  Localhost
                </Option>
                <Option value="https://advancly-borrower.test.vggdev.com/create-loan-account">
                  Develop
                </Option>
                <Option value="https://advancly-borrower.staging.vggdev.com/create-loan-account">
                  Staging
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Widget Primary Color"
              name={["customStyles", "primaryColor"]}
              rules={[{ required: true }]}
            >
              <Input
                size="large"
                style={{
                  backgroundColor: customStyles.primaryColor,
                  color: "#FFF",
                }}
                onChange={(e) =>
                  handleColorChange("primaryColor", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              label="Widget Primary Deep Color"
              name={["customStyles", "primaryDeepColor"]}
              rules={[{ required: true }]}
            >
              <Input
                size="large"
                style={{
                  backgroundColor: customStyles.primaryDeepColor,
                  color: "#FFF",
                }}
                onChange={(e) =>
                  handleColorChange("primaryDeepColor", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label="Widget Primary Light Color"
              name={["customStyles", "primaryLightColor"]}
              rules={[{ required: true }]}
            >
              <Input
                size="large"
                style={{
                  backgroundColor: customStyles.primaryLightColor,
                  color: "#FFF",
                }}
                onChange={(e) =>
                  handleColorChange("primaryLightColor", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <br />
        <br />
      </div>
    </LayoutComponent>
  );
};

export default UserForm;
