import React from "react";

interface IProps {
  children?: React.ReactNode;
}

const LayoutComponent = ({ children }: IProps) => {
  return <main className="layout">{children}</main>;
};

export default LayoutComponent;
