import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserProps } from "../interfaces/user";

interface WidgetProps {
  userData: UserProps;
}

const EmbedWidget: React.FC<WidgetProps> = ({ userData }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const history = useHistory();
  const widgetUrl = userData.widgetUrl;

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== new URL(widgetUrl).origin) return; // Security check
      if (event.data.status === "cancel") {
        history.push("/"); // Navigate back to form
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [history, widgetUrl]);

  useEffect(() => {
    console.log({
      userData,
    });
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        console.log("Iframe loaded, sending data...");
        iframeRef.current?.contentWindow?.postMessage(userData, widgetUrl);
      };
    }
  }, [userData, widgetUrl]);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <br />
      <iframe
        title="Advancly Embed"
        ref={iframeRef}
        src={widgetUrl}
        style={{
          height: "90%",
          width: "100%",
          border: "none",
        }}
        allowFullScreen
      />
    </div>
  );
};

export default EmbedWidget;
