import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UserForm from "./components/UserForm";
import PreviewPage from "./components/PreviewPage";
import "./App.less";
import "./index.css";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={UserForm} />
        <Route path="/preview" exact component={PreviewPage} />
      </Switch>
    </Router>
  );
};

export default App;
