// src/components/Preview.tsx
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Card, Typography } from "antd";
import logo from "../assets/advancly-logo-full.png";
import EmbedWidget from "./EmbedWidget";
import LayoutComponent from "./Layout.component";

const { Title, Text } = Typography;

const PreviewPage: React.FC = () => {
  const location = useLocation<{ data: any }>();
  const history = useHistory();
  const [loadWidget, setLoadWidget] = React.useState(false);

  const userData = location.state?.data;
  if (!userData) {
    history.push("/"); // Redirect back to form if data is missing
    return null;
  }

  const handleBack = () => {
    history.goBack();
  };

  const isString = (value: any): value is string => {
    return typeof value === "string";
  };

  const renderValue = (value: any) => {
    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value, null, 2); // Beautify the JSON string
    }
    return value?.toString();
  };

  return (
    <LayoutComponent>
      <div>
        {loadWidget ? (
          <EmbedWidget userData={userData} />
        ) : (
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Title
                  level={3}
                  style={{ color: "var(--primary-color)", marginBottom: "0px" }}
                >
                  Preview Data
                </Title>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "100px", objectFit: "contain" }}
                />
              </div>
            }
          >
            {Object.entries(userData).map(([key, value]) => (
              <div key={key} style={{ marginBottom: "10px" }}>
                <Text strong>{`${key
                  .replace(/_/g, " ")
                  .replace(/^\w/, (c) => c.toUpperCase())}: `}</Text>
                <Text
                  style={{
                    marginLeft: "10px",
                    backgroundColor:
                      key.includes("Color") && isString(value)
                        ? value
                        : "transparent",
                  }}
                >
                  {renderValue(value)}
                </Text>
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button onClick={handleBack}>Go Back</Button>
              <Button type="primary" onClick={() => setLoadWidget(true)}>
                Load Widget
              </Button>
            </div>
          </Card>
        )}
      </div>
    </LayoutComponent>
  );
};

export default PreviewPage;
